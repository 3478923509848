
import { computed, defineComponent, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';
import { Races } from '@/utils/constants';

// Components
import Loader from '@/components/Layout/Loader.vue';

export default defineComponent({
  name: 'Map',

  components: {
    Loader,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore(key);

    let timeout: ReturnType<typeof setInterval>;

    const map = computed(() => store.getters['api/map']);
    const id = computed(() => store.getters['persist/id']);
    const location = computed(() => store.getters['api/location']);
    const locationData = computed(() => store.getters['api/locationData']);
    const hero = computed(() => store.getters['persist/id']);
    const race = computed(() => store.getters['persist/race']);
    const size = computed(() => store.getters['persist/config']?.size || null);

    onMounted(() => {
      store.dispatch('api/getMap', location.value);
      timeout = setInterval(() => {
        store.dispatch('api/getMap', location.value);
      }, 1000);
    });

    onBeforeUnmount(() => {
      store.dispatch('api/clearMap').then(() => {
        clearInterval(timeout);
      });
    });

    return {
      t,
      map,
      hero,
      location,
      race,
      Races,
      locationData,
      id,
      size,
    };
  },
});
