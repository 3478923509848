import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c38ef418"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "switch"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.graphics)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choizes, (choize) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["switch__item", choize.value === _ctx.graphics && 'switch__item--active']),
            key: `level${choize}`
          }, [
            (choize.value !== _ctx.graphics)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.change(choize)), ["prevent"])
                }, _toDisplayString(choize.title), 9, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(choize.title), 1))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}