// Prohects еnums ans constants
//////////////////////////////////////////////////////

// Types
import type { TConfig, TMessages } from '@/models/utils';

// Enums

// Gameplay

export enum Races {
  // Players
  human = 'human',
  reptiloid = 'reptiloid',

  // NPC
  // bidens = 'bidens',
  mutant = 'mutant',
  orc = 'orc',
  zombie = 'zombie',
  soldier = 'soldier',
  cyborg = 'cyborg',
}

export enum Lifecycle {
  born = 'born',
  idle = 'idle',
  attention = 'attention',
  attack = 'attack',
  dead = 'dead',
}

// Modules
export enum Names {
  audio = 'audio',
  world = 'world',
  atmosphere = 'atmosphere',
  players = 'players',
  hero = 'hero',
  enemies = 'enemies',
  zombies = 'zombies',
  trees = 'trees',
  stones = 'stones',
  stones2 = 'stones2',
  grasses = 'grasses',
  hills = 'hills',
  points = 'points',
  things = 'things',
}

export enum Modes {
  idle = 'idle',
  active = 'active',
  dead = 'dead',
}

export enum Picks {
  dead = 'dead',
  thing = 'thing',
}

export enum Damages {
  kick = 'kick',
  light = 'light',
  shot = 'shot',
}

export enum Moves {
  right = 'right',
  left = 'left',
  top = 'top',
  bottom = 'bottom',
}

export enum Things {
  grenades = 'grenades',
  vodka = 'vodka',
  stew = 'stew',

  // Rare
  go = 'go',
}

export enum Animations {
  // Stand
  stand = 'stand',
  standforward = 'standforward',
  standback = 'standback',
  standleft = 'standleft',
  standright = 'standright',
  run = 'run',

  // Hide
  hide = 'hide',
  hideback = 'hideback',
  hideleft = 'hideleft',
  hideright = 'hideright',
  hideforward = 'hideforward',

  // Fire
  firestand = 'firestand',
  firestandforward = 'firestandforward',
  firehide = 'firehide',
  firehideforward = 'firehideforward',

  // Others
  hit = 'hit',
  jump = 'jump',
  dead = 'dead',

  // NPC
  idle = 'idle',
  walking = 'walking',
  kick = 'kick',
  cry = 'cry',
  attack = 'attack',
  back = 'back',
}

// GUI

export enum Textures {
  sky = 'sky',
  night = 'night',
  ground = 'ground',
  ground2 = 'ground2',
  concrette = 'concrette',
  concrette2 = 'concrette2',
  glass = 'glass',
  glassspecial = 'glassspecial',
  metallDark = 'metallDark',
  metall = 'metall',
  metall2 = 'metall2',
  fire = 'fire',
  purple = 'purple',
  light = 'light',
  pseudo = 'pseudo',
  scale = 'scale',
  hole = 'hole',
  blood = 'blood',
  grass = 'grass',
  playerred = 'playerred',
  playerblue = 'playerblue',
  vodka = 'vodka',
  go = 'go',
  zone = 'zone',
  trash = 'trash',
  road = 'road',
  yellow = 'yellow',
  wood = 'wood',
}

export enum Audios {
  // World
  wind = 'wind',
  door = 'door',

  // Players
  steps = 'steps',
  jumpstart = 'jumpstart',
  jumpend = 'jumpend',
  shot = 'shot',
  hit = 'hit',
  dead = 'dead',
  pick = 'pick',
  level = 'level',
  click = 'click',
  gosong = 'gosong',

  // Weapon
  explosion = 'explosion',
  light = 'light',
  
  // NPC

  // Zombie
  zombieidle = 'zombieidle',
  zombiehit = 'zombiehit',
  zombiedead = 'zombiedead',
  
  // Bidens
  // bidensidle = 'bidensidle',
  // bidenshit = 'bidenshit',
  // bidensdead = 'bidensdead',

  // Mutant
  mutantdead = 'mutantdead',
  mutantjumpend = 'mutantjumpend',
  mutanthit = 'mutanthit',
  mutantidle = 'mutantidle',
  mutantsteps = 'mutantsteps',

  // Orc
  orchit = 'orchit',
  orcidle = 'orcidle',
  orcdead = 'orcdead',

  // Soldier
  soldierhit = 'soldierhit',
  soldieridle = 'soldieridle',
  soldierdead = 'soldierdead',

  // Cyborg
  // cyborghit = 'cyborghit',
  // cyborgdead = 'cyborgdead',
  // cyborgsteps = 'cyborgsteps',
  // cyborgidle = 'cyborgidle',
}

export enum Colors {
  white = 0xffffff,
  black = 0x000000,
  yellow = 0xfed564,
  yellowDark = 0xe6a800,
  fog = 0xa48ed8,
  sun = 0xfdb813,
  toruch = 0xffff99,
  sky = 0x77deac,
  metall = 0x999999,
  metall2 = 0xaa9999,
  fire = 0xff6666,
  scale = 0x681a13,
  glass = 0xaaaaaa,
  blood = 0x8d0000,
  grass = 0x0aaf09,
  red = 0xff0000,
  blue = 0x0000ff,
  bluelight = 0x00bfff,

  concrette = 0x6f6f6f,
  stones =  0x3f1f0f, // северо-восток
  stones2 = 0x1f0f1f, // юго-восток
  stones3 = 0x4f4f4f, // северо-запад
  stones4 = 0xa0a0f0, // юго-запад
}

enum Languages {
  en = 'en',
  ru = 'ru',
}

// Configuration

const isProd = process.env.NODE_ENV === 'production';
const apiUrl = process.env.VUE_APP_API_URL;
export const API_URL = isProd
  ? apiUrl || '//api.robot-game.ru'
  : apiUrl || 'http://localhost:3000';

export const LANGUAGES: string[] = [Languages.en, Languages.ru];

// Конфиг
export const DESIGN: TConfig = {
  BREAKPOINTS: {
    desktop: 1025,
  },
  CAMERA: {
    fov: 80,
    fog: Colors.fog,
  },
  HIT_TIMEOUT: 500, // ms
  MESSAGES_TIMEOUT: 3000, // ms
  DEFAULT_VOLUME: 0.3,
  GAMEPLAY: {
    PLAYER_SPEED: 20,
    PLAYER_HEIGHT: 2,
    JUMP: 20,
    GRAVITY: 40,
    SHOTS_SPEED: 50,
  },
  UPDATE_TIME: 100,
  EFFECT_TIME: 1500,
};

// Экранный помощник
export const ScreenHelper = (() => {
  const DESKTOP = DESIGN.BREAKPOINTS.desktop;

  const isDesktop = () => {
    return window.matchMedia(`(min-width: ${DESKTOP}px)`).matches;
  };

  const isBro = () => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isYandex = navigator.userAgent.search(/YaBrowser/) > 0;
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    return isChrome || isYandex || isFirefox;
  };

  return {
    isDesktop,
    isBro,
  };
})();

// Переводы

export const MESSAGES: TMessages = {
  [Languages.en]: {
    enter: 'Enter',
    name: 'The whole world is in ruins',
    nick: 'Your nickname:',
    nick2: '(Only latin)',
    race: 'Select your race:',
    gadgetsgate: 'The game is for desktop browsers only!',
    chromegate:
      'In order to play, open in the Google Chrome (or Yandex) browser (Firefox not recommended)',
    startbutton: 'Play',
    restartbutton: 'Restart',
    control1: 'Shot: Left mouse button',
    control2f: 'Forward',
    control2b: 'Backward',
    control2l: 'Left',
    control2r: 'Rigth',
    control3: 'Jump',
    control4: 'Run',
    control5: 'Hidden movement',
    control6: 'Look: Mouse (If it stops working, press P or Ecs and select Play)',
    control7: 'Optical sight: Right mouse button',
    control8: 'Help and settings',
    control9: 'Map',
    control10: 'Menu',
    control11: 'Action',
    control12: 'Open chat',
    control12s: 'Send message',
    control12с: 'Close chat',
    link: 'Join the community on telegram',
    copyright: '© Levon Gambaryan Bro Games',
    gameover: 'Game Over',
    level: 'NEW LEVEL:',

    faq: 'Rules and settings',

    graphics: 'Graphics',
    graphicsmore: 'Evaluate the capabilities of your computer',
    graphicsmore2: 'and restart game',
    graphicsmin: 'Min',
    graphicsmid: 'Middle',
    graphicsmax: 'Max',
    restartbutton2: 'Restart',

    sounds: 'Sounds',
    sounds0: '(Levels for all sounds except beep - will be applied after reboot)',
    sounds1: 'Wind',
    sounds2: 'Sounds on hero',
    sounds3: 'Sounds of world',
    sounds4: 'Music',
    sounds5: 'Beep',

    help8: 'Settings',
    help1: 'Plot and basic rules',
    help2: 'Controls and dials',
    help3: 'NPC',
    help4: 'Items',
    help5: 'Future features',
    help6: 'Author',
    help7: 'Features',
    upgradebutton: 'Upgrade',
    cancelbutton: 'Cancel',
    defaultControl: 'To default',
    reservedbuttons: 'Attention! The number keys 1 .. 0 as well as Tab, Esc and Alt are reserved. The game developer knows that you can use AltLeft in your layout, but unfortunately this is how the Web Pointer Lock API works.',

    feature1: '* Several types of different weapons. Not only grenades, but also quick-fire and laser ones. Mines.',
    feature2: '* Flying NPCs. Drones protecting locations from players. And birds for hunting.',
    feature3: '* More items. Modifier items for a more interesting battle.',

    ['feature1.5.1.1']: 'The hint is available immediately from the first screen, outside the game.',
    ['feature1.5.1.2']: 'Improved attachment of weapons to players.',
    ['feature1.5.1.3']: 'Reduced damage to a character when firing a grenade launcher at his feet or at the wall in front of him.',
    ['feature1.5.1.4']: 'Reduced ranged damage to NPCs and especially damage to players from it.',
    ['feature1.5.2.1']: 'Legends for map.',
    ['feature1.5.3.1']: 'The game is deployed with not one, but nine locations where you can install flags of the game races (except for the starting locations for the game races).',
    ['feature1.6.2.1']: 'Added the ability to change graphics quality.',
    ['feature1.6.5.1']: 'Added the ability to adjust the volume of the game\'s sound design.',
    ['feature1.7.0.1']: 'Added the ability to redefine control keys in the game.',

    author: 'Levon Alekseevich Gambaryan',

    maphelp1: 'You',
    maphelp2: 'Players of your race',
    maphelp3: 'Players of a different race',
    maphelp4: 'Friendly NPCs',
    maphelp5: 'Hostile NPCs',
    maphelp6: 'Corpses',
    maphelp7: 'Wells',
    maphelp8: 'Toxic zones',

    history: 'In preparation for the invasion, advanced Reptilian emissaries were embedded in the leadership of TNCs, investment funds and the US Democratic Party. They unleashed a nuclear war, and in a few hours of gunfire, the whole Earth was turned into a poisoned desert, and the cities into ruins. A couple hundred years have passed and many living beings have mutated into toxic monsters aimlessly loitering among the ruins and waging a race war against everyone. The main forces of the Reptiles also landed on the planet to finish what they started. You can play either as Human Survivors, rebels resisting final colonization, or as alien invaders.',
    history1: '(Marked with ',
    history2: 'red',
    history3: ' and ',
    history4: 'blue',
    history5: ' dots)',
    rule1: 'In the center of each location there are control points and shelters. You can set and change flags on them. If you died in a location that already belongs to your race, then you will be reborn on it. Otherwise - at the original command post of the race, where the flag cannot be changed.',
    rule2: 'The speed of control depends on the character’s health level and the degree of his poisoning. Players cannot fire heavy weapons while moving or jumping. If you throw a grenade launcher shell at your feet, you will die.',
    rule3: 'Now the magic and energy weapons of the NPCs are breaking through the walls. Players with a low level should not be careful and not allow themselves to be hit with a long-range shot.',
    rule4: 'Be careful when moving between locations. It’s better to stock up on stewed meat and vodka - you can go straight into a poisoned area or be attacked by the nepis walking there. It is much calmer to travel on asphalt roads.',
    helpfriend: 'Red и NATO Soldiers',
    helpfriend2: 'Helps one of the game races!',
    helpfriend3: 'soldiers and players for another flag',

    scale11: 'Red scale',
    scale12: 'shows the character\'s health level.',
    scale21: 'Purple scale',
    scale22: 'shows the level of fatigue. If you are tired, you cannot jump or run until it recovers.',
    scale31: 'Blue scale',
    scale32: 'shows your water supply. Look for wells (blue dots on the map) to fill your flask.',
    scale41: 'Green scale',
    scale42: 'shows your food supply.',
    scale51: 'Yellow scale',
    scale52: 'shows the level of poisoning.',
    scalemore: 'To the right of the scales there are icons for quick access to items (press keys 1 ... 0 to apply), indicators of their current and maximum quantity in the backpack. In the right corner you see the number of available charges for weapons, as well as their maximum number that can be collected.',

    nonplayersracestext: 'Over time, non-player units gain experience (shown as numbers above any character). When picking up their corpses, players receive experience points and poisoning relative to the NPC\'s experience level. Regeneration in older individuals slows down. Red soldiers help the Rebels, and NATO members accept the Reptilians as a new power and order on the planet.',
    things: 'Items',
    thingstext: 'Some items, such as shots for weapons, are “applied” immediately when picked up. Others - you can dial a limited number and use them via shortcut keys whenever it is convenient for you.',
    thingsrare: 'Rare Items',

    enemy: 'Enemies',
    important: 'Important enemies',
    friend: 'Ally',
    kick: 'Melee',
    attack: 'Ranged combat',
    regeneration: 'Regeneration',
    intelligence: 'Intelligence',
    armor: 'Armor',
    exp: 'Gives experience when selecting',
    toxic: 'Causes poison when picked up',
    speed: 'Speed',
    health: 'Gives health when used',
    exp2: 'Reduces experience when used',
    toxic2: 'Poison when used',
    food: 'Food when used',
    water: 'Water when used',
    numbers: 'Contains units',
    max: 'Maximum quantity',

    hiddenMoveEnabled: 'You move in stealth mode.',
    hiddenMoveDisabled: 'Stealth mode disabled.',
    tired: 'Your is tired of running.',
    recovered: 'Your can run again.',
    exitOn: 'You are at the exit from the location!',
    exitOff: 'Exit from a location far away.',
    foodlow: 'You need to eat!',
    waterlow: 'You need to find water!',
    toxichight: 'You need to reduce poisoning',
    full: 'There\'s already too much of this item!',
    well: 'Fill the flask with water!',
    toxiczone: 'You are in a poisoned area!',

    door: 'Press E to open the door.',
    point: 'Raise your race\'s flag at the control station?',
    pointStart: 'The flag cannot be changed at this point!',
    pointGood: 'The point already belongs to your race!',
    pick: 'Pick: ',

    chat1: ' - send a message, ',
    chat2: ' - close the chat.',
    close: 'Close',
    send: 'Send message',

    [Races.human]: 'Red commander',
    [Races.reptiloid]: 'Reptiloid',
    [Races.zombie]: 'Radfeministka',
    [Races.mutant]: 'Narcomutant',
    [Races.orc]: 'Alcoork',
    [Races.soldier]: 'NATO soldier',
    [Races.cyborg]: 'Red soldier',

    [Things.grenades]: 'Grenades',
    [Things.vodka]: 'Vodka',
    [Things.stew]: 'Stew',
    [Things.go]: 'Worn Civil Defense CD',
  },
  [Languages.ru]: {
    enter: 'Играть',
    nick: 'Тебя зовут:',
    nick2: '(Только латиницей, к сожалению)',
    race: 'Выбери свою сторону:',
    name: 'Весь мир в труху',
    gadgetsgate: 'Игра только для десктопных браузеров!',
    chromegate:
      'Для того чтобы играть откройте в браузере Google Chrome (или Яндекс), Firefox не рекомендуется',
    startbutton: 'Играть',
    restartbutton: 'Сначала',
    control1: 'Выстрел: Левая кнопка мыши',
    control2f: 'Вперед',
    control2b: 'Назад',
    control2l: 'Влево',
    control2r: 'Вправо',
    control3: 'Прыжок',
    control4: 'Бежать',
    control5: 'Cкрытное передвижение',
    control6: 'Осмотреться: Мышь (Если перестало работать - нажмите P или Ecs и выберите Играть)',
    control7: 'Оптический прицел: Правая кнопка мыши',
    control8: 'Подсказка и настройки',
    control9: 'Карта',
    control10: 'Меню',
    control11: 'Действие',
    control12: 'Открыть чат',
    control12s: 'Отправить сообщение',
    control12с: 'Закрыть чат',
    link: 'Присоединяйся к сообществу в телеграм',
    copyright: '© Levon Gambaryan Bro Games',
    gameover: 'Проиграл',
    level: 'НОВЫЙ УРОВЕНЬ:',

    faq: 'Правила и настройки',

    graphics: 'Графика',
    graphicsmore: 'Оцените возможности своей машины',
    graphicsmore2: 'и перезапустите игру',
    graphicsmin: 'Минимальная',
    graphicsmid: 'Средняя',
    graphicsmax: 'Максимальная',
    restartbutton2: 'Перезапуск',

    sounds: 'Звуки',
    sounds0: '(Уровни для всех звуков кроме beep - применятся после перезагрузки)',
    sounds1: 'Ветер',
    sounds2: 'Звуки на персонаже',
    sounds3: 'Звуки мира',
    sounds4: 'Музыка',
    sounds5: 'Beep',

    help8: 'Настройки',
    help1: 'Фабула и правила',
    help2: 'Управление и шкалы',
    help3: 'Неписи',
    help4: 'Предметы',
    help5: 'Планы разработки',
    help6: 'Автор',
    help7: 'Улучшения',
    upgradebutton: 'Изменить',
    cancelbutton: 'Отменить',
    defaultControl: 'Сбросить на стандартные',
    reservedbuttons: 'Внимание! Клавиши с цифрами 1 .. 0, а также Tab, Esc и Alt являются зарезервированными. Разработчик игры вкурсе, что вы можете использовать AltLeft в своей раскладке, но именно так, к сожалению, работает Web Pointer Lock API.',

    feature1: '* Несколько видов разного оружия. Не только гранаты - быстрострельное и лазерное. Мины.',
    feature2: '* Летающие неписи. Охраняющие локации от игроков дроны. Птицы для охоты.',
    feature3: '* Больше предметов. Предметы-модификаторы для более интересного боя.',

    ['feature1.5.1.1']: 'Подсказка доступна сразу с первого экрана, вне игры.',
    ['feature1.5.1.2']: 'Улучшено крепление оружия к игрокам.',
    ['feature1.5.1.3']: 'Уменьшен урон персонажу при выстреле из гранатомета под ноги или в стену перед собой.',
    ['feature1.5.1.4']: 'Уменьшен урон от дальнего боя неписей и особенно урон игрокам от него.',
    ['feature1.5.2.1']: 'Легенды для карты.',
    ['feature1.5.3.1']: 'Игра развернута не с одной, а с девятью локациями, на которых можно устанавливать флаги игровых рас (кроме стартовых локаций для игровых рас).',
    ['feature1.6.2.1']: 'Добавлена возможность менять качество графики.',
    ['feature1.6.5.1']: 'Добавлена возможность настраивать громкость звукового оформления игры.',
    ['feature1.7.0.1']: 'Добавлена возможность переопределять управляющие клавиши в игре.',

    history: 'В рамках подготовки к вторжению, передовые эмиссары Рептилоидов были внедрены в руководство ТНК, инвестиционных фондов и Демократической партии США. Они развязали ядерную войну, и за несколько часов перестрелки вся Земля была превращена в отравленную пустыню, а города в руины. Прошло пара сотен лет и многие живые существа мутировали в токсичных монстров, бесцельно слоняющихся среди развалин, и ведущих расовую войну все против всех. На планету также высадились основные силы Рептилов, чтобы довершить начатое. Вы можете играть или за выживших людей, повстанцев, сопротивляющихся окончательной колонизации, или за инопланетных захватчиков.',
    history1: '(Помечены ',
    history2: 'красными',
    history3: ' и ',
    history4: 'синими',
    history5: ' точками)',
    rule1: 'В центре каждой локации есть контрольные точки, укрытия. На них можно устанавливать и менять флаги (дает много опыта). Если вы погибли на локации которая уже принадлежит вашей расе - то переродитесь на ней. В противном случае - на исходном командном пункте расы, на котором нельзя поменять флаг.',
    rule2: 'Cкорость контрола зависит от уровня здоровья персонажа и степени его отравления. Игроки не могут стрелять из тяжелого оружия когда перемешаются или прыгают. Если вы кинете снаряд из гранатомета слишком близко к себе, то получите урон.',
    rule3: 'Сейчас магия и энергетическое оружие неписей - пробивают сквозь стены. Игрокам с низким уровнем, стоит быть осторожнее, и не доводить до удара дальним по себе.',
    rule4: 'Будьте внимательными при переходе между локациями. Лучше запаситесь тушенкой и водкой - вы можете перейти прямо в отравленную местность или под удар прогуливающейся там неписи. Намного спокойнее перемещаться по асфальтированным дорогам.',
    helpfriend: 'Красные и НАТОвские солдаты',
    helpfriend2: 'Помогает одной из игровых рас!',
    helpfriend3: 'солдаты и игроки за другой флаг',

    author: 'Левон Алексеевич Гамбарян',

    maphelp1: 'Ты',
    maphelp2: 'Игроки твоей расы',
    maphelp3: 'Игроки другой расы',
    maphelp4: 'Дружественные неписи',
    maphelp5: 'Враждебные неписи',
    maphelp6: 'Трупы',
    maphelp7: 'Колодцы',
    maphelp8: 'Отравленные зоны',

    scale11: 'Красная шкала',
    scale12: 'показывает уровень здоровья персонажа.',
    scale21: 'Фиолетовая шкала',
    scale22: 'показывает уровень усталости. Если вы устали - вы не можете прыгать и бегать пока она не востановится.',
    scale31: 'Голубая шкала',
    scale32: 'показывает ваш запас воды. Ищите колодцы (голубые точки на карте) для того чтобы наполнить флягу.',
    scale41: 'Зеленая шкала',
    scale42: 'показывает ваш запас пищи.',
    scale51: 'Желтая шкала',
    scale52: 'показывает уровень отравления.',
    scalemore: 'Справа от шкал располагаются пиктограммы быстрого доступа к предметам (нажмите клавиши 1 ... 0 чтобы применить), индикаторы их актуального и максимального количества в рюкзаке. В правом углу вы видете количество имеющихся зарядов для оружия, а также их маскимальное количество которое можно собрать.',

    friendnpc: 'Помогает одной из игровых рас!',
    nonplayersracestext: 'С ходом времени неигровые юниты получают опыт (показывается в виде цифр над любым персонажем). При подборе их трупов игроки получают очки опыта и отравление относительно уровня опыта неписи. Регенерация у более старых особей - замедляется. Красные солдаты помогают Повстанцам, а Натовцы - принимают Рептилоидов как новую власть, порядок на планете.',
    things: 'Предметы',
    thingstext: 'Некоторые предметы, например, выстрелы к оружию, "применяются" сразу при подборе. Другие - можно набирать некоторое ограниченное количество и применять через клавиши быстрого доступа когда вам удобно.',
    thingsrare: 'Редкие предметы',

    enemy: 'Враги',
    important: 'Особенно ненавидит',
    friend: 'Союзник',
    kick: 'Ближний бой',
    attack: 'Дальний бой',
    regeneration: 'Регенерация',
    intelligence: 'Интеллект',
    armor: 'Броня',
    exp: 'Дает опыта при подборе',
    toxic: 'Вызывает отравление при подборе',
    speed: 'Скорость',
    health: 'Дает здоровья при применении',
    exp2: 'Отнимает опыта при применении',
    toxic2: 'Отравление при применении',
    food: 'Еда при применении',
    water: 'Вода при применении',
    numbers: 'Содержит единиц',
    max: 'Максимальное количество',

    hiddenMoveEnabled: 'Вы двигаетесь в скрытном режиме.',
    hiddenMoveDisabled: 'Скрытный режим отключен.',
    tired: 'Вы устали от бега.',
    recovered: 'Вы снова можете бегать.',
    exitOn: 'Вы на выходе с локации!',
    exitOff: 'Выход с локации далеко.',
    foodlow: 'Вам необходимо поесть!',
    waterlow: 'Вам необходимо найти воду!',
    toxichight: 'Вам необходимо снизить отравление!',
    full: 'Этого предмета уже слишком много!',
    well: 'Наполнить флягу водой!',
    toxiczone: 'Вы находитесь в отравленной местности!',

    door: 'Нажмите E для того чтобы открыть дверь.',
    point: 'Поднять флаг своей расы на контрольной точке?',
    pointStart: 'На этой точке нельзя поменять флаг!',
    pointGood: 'Точка уже принадлежит вашей расе!',
    pick: 'Подобрать: ',

    chat1: ' - отправить сообщение, ',
    chat2: ' - закрыть чат.',
    close: 'Закрыть',
    send: 'Отправить',

    [Races.human]: 'Красный коммандир',
    [Races.reptiloid]: 'Рептилоид',
    [Races.zombie]: 'Радфеминистка',
    [Races.mutant]: 'Наркомутант',
    [Races.orc]: 'Алкоорк',
    [Races.soldier]: 'Натовец',
    [Races.cyborg]: 'Красный солдат',

    [Things.grenades]: 'Гранаты',
    [Things.vodka]: 'Водка',
    [Things.stew]: 'Тушенка',
    [Things.go]: 'Потертый CD Гражданской Обороны',
  },
};
