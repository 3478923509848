
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';

// Utils
import { beep } from '@/utils/utils';

export default defineComponent({
  name: 'AudioSwitch',

  props: ['number'],

  setup(props) {
    const store = useStore(key);

    const sound = computed(() => store.getters[`persist/sounds${props.number}`]);
    const sounds5 = computed(() => store.getters['persist/sounds5']);

    const change = (value: number): void => {
      store.dispatch('persist/setPersistState', {
        field: `sounds${props.number}`,
        value,
      });
      beep(false, sounds5.value);
    };

    return {
      sound,
      change,
    };
  },
});
