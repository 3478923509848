
import { defineComponent, computed, onMounted, ref, Ref, watch } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';

// Emmiter
import emitter from '@/utils/emitter';

// Constants
import { ScreenHelper } from '@/utils/constants';
import { Races, Things } from '@/utils/constants';

// Types
import { EmitterEvents } from '@/models/api';

// Components
import Connect from '@/components/Connect.vue';
import Preloader from '@/components/Layout/Preloader.vue';
import Gate from '@/components/Layout/Gate.vue';
import Scene from '@/components/Scene/Scene.vue';
import LangSwitch from '@/components/Layout/LangSwitch.vue';
import Scale from '@/components/Layout/Scale.vue';
import Map from '@/components/Layout/Map.vue';
import Help from '@/components/Layout/Help.vue';
import Chat from '@/components/Layout/Chat.vue';

// Utils
import { beep, safeChar } from '@/utils/utils';

// Utils
import { restartDispatchHelper } from '@/utils/utils';

export default defineComponent({
  name: 'Layout',

  components: {
    Connect,
    Preloader,
    Scene,
    LangSwitch,
    Gate,
    Scale,
    Map,
    Help,
    Chat,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore(key);

    let isDesktop: Ref<boolean> = ref(false);
    let nickname: Ref<string> = ref('');
    let keyHealth: Ref<number> = ref(0);
    let isFirts: Ref<boolean> = ref(false);
    const isBro = ScreenHelper.isBro();
    let onWindowResize: () => void;
    let play: () => void;
    let enter: () => void;
    let reenter: () => void;
    let filter: (value: any) => void;
    let setRace: (value: string) => void;
    let openHelp: () => void;
    const isGameLoaded = computed(
      () => store.getters['preloader/isGameLoaded'],
    );
    const isEnter = computed(() => store.getters['persist/isEnter']);
    const isReload = computed(() => store.getters['not/isReload']);
    const locationData = computed(() => store.getters['api/locationData']);
    const isOnHit = computed(() => store.getters['api/isOnHit']);
    const health = computed(() => store.getters['api/health']);
    const name = computed(() => store.getters['persist/name']);
    const isGameOver = computed(() => store.getters['persist/isGameOver']);
    const isPause = computed(() => store.getters['persist/isPause']);
    const endurance = computed(() => store.getters['persist/endurance']);
    const isTired = computed(() => store.getters['persist/isTired']);
    const isOptical = computed(() => store.getters['not/isOptical']);
    const isMap = computed(() => store.getters['not/isMap']);
    const isHelp = computed(() => store.getters['not/isHelp']);
    const isChat = computed(() => store.getters['not/isChat']);
    const messages = computed(() => store.getters['not/messages']);
    const message = computed(() => store.getters['not/message']);
    const content = computed(() => store.getters['not/content']);
    const language = computed(() => store.getters['persist/language']);
    const race = computed(() => store.getters['persist/race']);
    const last = computed(() => store.getters['persist/last']);
    const isPick = computed(() => store.getters['not/isPick']);
    const isLevel = computed(() => store.getters['not/isLevel']);
    const exp = computed(() => Math.round(store.getters['api/exp']));
    const food = computed(() => Math.round(store.getters['persist/food']));
    const water = computed(() => Math.round(store.getters['persist/water']));
    const toxic = computed(() => Math.round(store.getters['persist/toxic']));
    const level = computed(() =>
      Math.floor(
        store.getters['api/exp'] / store.getters['persist/config']?.exp,
      ),
    );
    const grenades = computed(() => store.getters['persist/grenades']);
    const vodka = computed(() => store.getters['persist/vodka']);
    const stew = computed(() => store.getters['persist/stew']);
    const config = computed(() => store.getters['persist/config']);
    const isMove = computed(() => store.getters['not/isMove']);
    const isNotJump = computed(() => store.getters['not/isNotJump']);
    const shotTime = computed(() => store.getters['not/shotTime']);
    const sounds5 = computed(() => store.getters['persist/sounds5']);
    const controlPause = computed(() => store.getters['persist/controlPause']);
    const controlMap = computed(() => store.getters['persist/controlMap']);
    const controlHelp = computed(() => store.getters['persist/controlHelp']);
    const controlChat = computed(() => store.getters['persist/controlChat']);

    onMounted(() => {
      onWindowResize();
      window.addEventListener('resize', onWindowResize, false);

      nickname.value = name.value;
    });

    onWindowResize = () => {
      isDesktop.value = ScreenHelper.isDesktop();
    };

    reenter = () => {
      beep(false, sounds5.value);
      store
        .dispatch('persist/setPersistState', {
          field: 'last',
          value: locationData.value.id,
        })
        .then(() => {
          emitter.emit(EmitterEvents.reenter);
          restartDispatchHelper(store);
        });
    };

    enter = () => {
      beep(false, sounds5.value);
      emitter.emit(EmitterEvents.enter, {
        name: nickname.value,
        race: race.value,
        location: last.value,
      });
      store.dispatch('persist/setPersistState', {
        field: 'name',
        value: nickname.value,
      });
    };

    play = () => {
      store.dispatch('persist/setPersistState', {
        field: 'isPause',
        value: !isPause.value,
      });
    };

    setRace = (value) => {
      store.dispatch('persist/setPersistState', {
        field: 'race',
        value,
      });
      beep(false, sounds5.value);
    };

    filter = (value) => {
      if (value.data && !value.data.match(/[^0-9a-z\s]/gi)) beep(false, sounds5.value);
      else beep(true, sounds5.value);
      nickname.value = nickname.value.replace(/[^0-9a-z\s]/gi, '');
    };

    openHelp = () => {
      store.dispatch('not/setNotState', {
        field: 'isHelp',
        value: true,
      });
    };

    // Следим за загрузкой
    watch(
      () => isGameLoaded.value,
      () => {
        setTimeout(() => {
          isFirts.value = true;
        }, 0);
      },
    );

    // Следим за языком
    watch(
      () => language.value,
      () => {
        if (isFirts.value) beep(false, sounds5.value);
      },
    );

    // Следим за картой
    watch(
      () => isMap.value,
      () => {
        if (isFirts.value) beep(false, sounds5.value);
      },
    );

    // Следим за паузой
    watch(
      () => isPause.value,
      (value) => {
        if (isFirts.value && !value) beep(false, sounds5.value);
      },
    );

    // Следим за чатом
    watch(
      () => isChat.value,
      () => {
        if (isFirts.value) beep(false, sounds5.value);
      },
    );

    // Следим за подсказкой
    watch(
      () => isHelp.value,
      () => {
        if (isFirts.value) beep(false, sounds5.value);
      },
    );

    // Следим за здоровьем
    watch(
      () => health.value,
      () => {
        ++keyHealth.value;
      },
    );

    return {
      t,
      isDesktop,
      isBro,
      isGameLoaded,
      isEnter,
      isReload,
      isGameOver,
      isPause,
      isOptical,
      isTired,
      health,
      name,
      endurance,
      messages,
      message,
      content,
      play,
      enter,
      reenter,
      setRace,
      nickname,
      race,
      isOnHit,
      locationData,
      isMap,
      isHelp,
      isChat,
      language,
      keyHealth,
      isPick,
      isLevel,
      exp,
      level,
      grenades,
      Races,
      Things,
      filter,
      vodka,
      config,
      food,
      water,
      toxic,
      stew,
      isMove,
      isNotJump,
      shotTime,
      openHelp,
      controlPause,
      controlMap,
      controlHelp,
      controlChat,
      safeChar,
    };
  },
});
