
import { defineComponent, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';

// Utils
import { beep } from '@/utils/utils';

export default defineComponent({
  name: 'UpgradeButton',

  props: ['field', 'text', 'char'],

  setup(props) {
    const { t } = useI18n();
    const store = useStore(key);
    let click: () => void;
    let cancel: () => void;
    const sounds5 = computed(() => store.getters['persist/sounds5']);
    const isUpgrade = computed(() => store.getters['not/isUpgrade']);

    click = () => {
      store.dispatch('not/setNotState', {
        field: 'isUpgrade',
        value: props.field,
      });

      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('upgradeinput').focus();
      }, 100);

      beep(false, sounds5.value);
    };

    cancel = () => {
      store.dispatch('not/onUpgrade');

      beep(false, sounds5.value);
    };

    // Следим за стором
    watch(
      () => store.getters['not/key'],
      (value) => {
        if (isUpgrade.value && isUpgrade.value === props.field)
          store
            .dispatch('persist/setPersistState', {
              field: `control${props.field}`,
              value,
            })
            .then(() => {
              store.dispatch('not/onUpgrade');
              beep(false, sounds5.value);
            });
      },
    );

    return {
      t,
      click,
      isUpgrade,
      cancel,
    };
  },
});
