import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-451de5e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (choize) => {
      return _createElementVNode("li", {
        class: _normalizeClass(["switch__item", choize === _ctx.sound && 'switch__item--active']),
        key: `level${choize}`
      }, [
        (choize !== _ctx.sound)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "#",
              onClick: _withModifiers(($event: any) => (_ctx.change(choize)), ["prevent"])
            }, _toDisplayString(choize), 9, _hoisted_2))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(choize), 1))
      ], 2)
    }), 64))
  ]))
}