import type { Store } from 'vuex';
import type { State } from '@/store';

// Помощник перезагрузки
export const restartDispatchHelper = (store: Store<State>): void => {
  store
    .dispatch('not/setNotState', {
      field: 'isReload',
      value: true,
    }).then(() => {
      store.dispatch('persist/setPersistState', {
        field: 'isEnter',
        value: false,
      }).then(() => {
        store.dispatch('persist/reload').then(() => {
          store.dispatch('api/reload').then(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.location.reload(true);
          });
        });
      });
    });
};

// Помощник перехода на другую локацию
export const relocationDispatchHelper = (store: Store<State>): void => {
  store
    .dispatch('not/setNotState', {
      field: 'isReload',
      value: true,
    }).then(() => {
      store.dispatch('api/reload').then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location.reload(true);
      });
    });
};

// Beep (true - "плохой низкмй звук")
export const beep = (is = false, volume: number): void => {
  const ctx = new AudioContext();
  const oscillator = ctx.createOscillator();
  const gainNode = new GainNode(ctx, {
    gain: Number(process.env.VUE_APP_BEEP_VOLUME) * volume,
  });
  if (!is) oscillator.frequency.value = Math.random() * 2200 + 440;
  else oscillator.frequency.value = 330;
  oscillator.connect(gainNode).connect(ctx.destination);
  oscillator.start();
  oscillator.stop(is ? 0.3 : 0.15);
};

// Помощник кодов клавиш
export const safeChar = (code: number): string => {
  switch (code) {
    case 32:
      return 'Space';
    case 13:
      return 'Enter';
    case 16:
      return 'ShiftLeft';
    case 20:
      return 'CapsLock';
    case 17:
      return 'CntrLeft';
  }
  return String.fromCharCode(code);
};