import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ceba75a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "switch"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.language)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (value) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["switch__item", value === _ctx.language && 'switch__item--active']),
            key: `language${value}`
          }, [
            (value !== _ctx.language)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.changeLanguage(value)), ["prevent"])
                }, _toDisplayString(value), 9, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(value), 1))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}