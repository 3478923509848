
import { defineComponent, ref, Ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';

// Emmiter
import emitter from '@/utils/emitter';

// Types
import { EmitterEvents } from '@/models/api';

// Utils
import { beep, safeChar } from '@/utils/utils';

export default defineComponent({
  name: 'Chat',

  setup() {
    const { t } = useI18n();
    const store = useStore(key);

    let text: Ref<string> = ref('');
    let isFirts: Ref<boolean> = ref(false);
    let close: () => void;
    let send: () => void;
    let input: () => void;
    const isSendByEnter = computed(() =>
      Math.round(store.getters['not/isSendByEnter']),
    );
    const sounds5 = computed(() => store.getters['persist/sounds5']);
    const controlChatClose = computed(
      () => store.getters['persist/controlChatClose'],
    );
    const controlChatSend = computed(
      () => store.getters['persist/controlChatSend'],
    );

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('input').focus();

      setTimeout(() => {
        isFirts.value = true;
      }, 300);
    });

    close = () => {
      store.dispatch('not/setNotState', {
        field: 'isChat',
        value: false,
      });
    };

    send = () => {
      emitter.emit(EmitterEvents.send, text.value);
      text.value = '';
      store.dispatch('not/setNotState', {
        field: 'text',
        value: '',
      });
    };

    input = () => {
      store.dispatch('not/setNotState', {
        field: 'text',
        value: text.value,
      });
    };

    // Следим за вводом
    watch(
      () => text.value,
      () => {
        if (isFirts.value) beep(false, sounds5.value);
      },
    );

    // Следим за отправкой по Enter
    watch(
      () => isSendByEnter.value,
      (value) => {
        if (value) {
          text.value = '';
          store.dispatch('not/setNotState', {
            field: 'isSendByEnter',
            value: false,
          });
        }
      },
    );

    return {
      t,
      text,
      close,
      send,
      input,
      controlChatClose,
      controlChatSend,
      safeChar,
    };
  },
});
