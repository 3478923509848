import { Module } from 'vuex';

// API
import { APIService } from '@/utils/api';

// Types
import type { IStore, IStoreModule, TFieldPayload } from '@/models/store';
import { Races } from '@/utils/constants';

const initialState: IStoreModule = {
  config: null, // Конфигурация геймплея - неписей и предметов
  day: 0, // Время суток
  id: null, // Идентификатор игрока
  name: null, // Имя игрока
  isEnter: false, // Cервер знает имя и расу пользователя?
  race: Races.human, // Раса которую пользователь выбрал последний раз
  last: null, // Последняя локация на которую заходил игрок
  isExit: false, // Рядом с выходом на другую локацию?

  language: null, // Язык интерфейса
  isPause: true, // Сейчас пауза?
  isGameOver: false, // Умер?
  messages: [], // Сообщения сейчас

  // Gameplay
  endurance: 100, // Выносливость
  food: 100, // Запас пищи
  water: 100, // Запас воды
  toxic: 10, // Отравление
  isHide: false, // Скрытый режим?
  isRun: false, // Бег?
  isJump: false, // В прыжке?
  isTired: false, // Устал?

  // Things
  grenades: 50,
  vodka: 0,
  stew: 0,

  // Settings
  graphics: 1,
  sounds1: 5,
  sounds2: 5,
  sounds3: 5,
  sounds4: 5,
  sounds5: 5,

  // Control
  controlReserved: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 27, 9, 18],
  controlRun: 16, // ShiftLeft
  controlPause: 80, // P
  controlMap: 77, // M
  controlHelp: 72, // H
  controlChat: 82, // R
  controlChatClose: 17, // CntrLeft
  controlChatSend: 13, // Enter
  controlHide: 67, // С
  controlForward: 87, // W
  controlBackward: 83, // S
  controlLeft: 65, // A
  controlRight: 68, // D
  controlJump: 32, // Space
  controlPick: 69, // E
};
const initial = initialState;

const persist: Module<IStoreModule, IStore> = {
  namespaced: true,
  state: initial,

  getters: {
    config: (state: IStoreModule) => state.config,
    id: (state: IStoreModule) => state.id,
    name: (state: IStoreModule) => state.name,
    isEnter: (state: IStoreModule) => state.isEnter,
    isExit: (state: IStoreModule) => state.isExit,
    language: (state: IStoreModule) => state.language,
    isPause: (state: IStoreModule) => state.isPause,
    endurance: (state: IStoreModule) => state.endurance,
    food: (state: IStoreModule) => state.food,
    water: (state: IStoreModule) => state.water,
    toxic: (state: IStoreModule) => state.toxic,
    isHide: (state: IStoreModule) => state.isHide,
    isRun: (state: IStoreModule) => state.isRun,
    isJump: (state: IStoreModule) => state.isJump,
    isTired: (state: IStoreModule) => state.isTired,
    isGameOver: (state: IStoreModule) => state.isGameOver,
    day: (state: IStoreModule) => state.day,
    race: (state: IStoreModule) => state.race,
    last: (state: IStoreModule) => state.last,
    grenades: (state: IStoreModule) => state.grenades,
    vodka: (state: IStoreModule) => state.vodka,
    stew: (state: IStoreModule) => state.stew,
    graphics: (state: IStoreModule) => state.graphics,
    sounds1: (state: IStoreModule) => state.sounds1,
    sounds2: (state: IStoreModule) => state.sounds2,
    sounds3: (state: IStoreModule) => state.sounds3,
    sounds4: (state: IStoreModule) => state.sounds4,
    sounds5: (state: IStoreModule) => state.sounds5,
    controlReserved: (state: IStoreModule) => state.controlReserved,
    controlRun: (state: IStoreModule) => state.controlRun,
    controlPause: (state: IStoreModule) => state.controlPause,
    controlMap: (state: IStoreModule) => state.controlMap,
    controlHelp: (state: IStoreModule) => state.controlHelp,
    controlChat: (state: IStoreModule) => state.controlChat,
    controlChatClose: (state: IStoreModule) => state.controlChatClose,
    controlChatSend: (state: IStoreModule) => state.controlChatSend,
    controlHide: (state: IStoreModule) => state.controlHide,
    controlForward: (state: IStoreModule) => state.controlForward,
    controlBackward: (state: IStoreModule) => state.controlBackward,
    controlLeft: (state: IStoreModule) => state.controlLeft,
    controlRight: (state: IStoreModule) => state.controlRight,
    controlJump: (state: IStoreModule) => state.controlJump,
    controlPick: (state: IStoreModule) => state.controlPick,
    controls: (state: IStoreModule) => [
      ...state.controlReserved,
      state.controlRun,
      state.controlPause,
      state.controlMap,
      state.controlHelp,
      state.controlChat,
      state.controlChatClose,
      state.controlChatSend,
      state.controlHide,
      state.controlForward,
      state.controlBackward,
      state.controlLeft,
      state.controlRight,
      state.controlJump,
      state.controlPick,
    ],
  },

  actions: {
    setPersistState: (context, payload: TFieldPayload): void => {
      // Смерть от жажды, голода или отравления
      if (
        ((payload.field === 'food' || payload.field === 'water') &&
          payload.value <= 0) ||
        (payload.field === 'toxic' && payload.value >= 100)
      )
        context.dispatch('setPersistState', {
          field: 'isGameOver',
          value: true,
        });

      if (
        payload.field === 'endurance' &&
        context.getters.endurance < 1 &&
        !context.getters.isTired
      )
        context.commit('setPersistState', { field: 'isTired', value: true });
      else if (
        payload.field === 'endurance' &&
        context.getters.endurance > 100 &&
        context.getters.isTired
      )
        context.commit('setPersistState', { field: 'isTired', value: false });
      else context.commit('setPersistState', payload);
    },

    getConfig: ({ commit }): void => {
      APIService.getConfig().then((res) => {
        commit('getConfig', res);
      });
    },

    onUse: ({ commit }, payload): void => {
      commit('onUse', payload);
    },

    defaultControl: ({ commit }): void => {
      commit('defaultControl');
    },

    reload: ({ commit }): void => {
      commit('reload');
    },
  },

  mutations: {
    setPersistState: (state: IStoreModule, payload: TFieldPayload): void => {
      if (payload.field === 'endurance') {
        if (state[payload.field] < 100 && payload.value > 0)
          state[payload.field] += payload.value;
        else if (state[payload.field] > 100 && payload.value > 0)
          state[payload.field] = 100;
        else state[payload.field] += payload.value;
      } else state[payload.field] = payload.value;

      if (payload.field === 'toxic') {
        if (state.toxic > 100) state.toxic = 100;
        else if (state.toxic < 0) state.toxic = 0;
      }
    },

    onUse: (state: IStoreModule, payload): void => {
      // console.log('onUse persist store mutation: ', payload);
      state.toxic += state.config.things[payload].toxic;
      if (state.toxic > 100) state.toxic = 100;
      else if (state.toxic < 0) state.toxic = 0;
      state.water += state.config.things[payload].water;
      if (state.water > 100) state.water = 100;
      else if (state.water < 0) state.water = 0;
      state.food += state.config.things[payload].food;
      if (state.food > 100) state.food = 100;
      else if (state.food < 0) state.food = 0;
    },

    getConfig: (state: IStoreModule, payload): void => {
      // console.log('getConfig persist store mutation: ', payload);
      state.config = payload;
    },

    reload: (state: IStoreModule): void => {
      state.isEnter = initialState.isEnter;
      state.isPause = initialState.isPause;
      state.endurance = initialState.endurance;
      state.food = initialState.food;
      state.water = initialState.water;
      state.toxic = initialState.toxic;
      state.isHide = initialState.isHide;
      state.isRun = initialState.isRun;
      state.isJump = initialState.isJump;
      state.isTired = initialState.isTired;
      state.isGameOver = initialState.isGameOver;
      state.grenades = initialState.config?.things?.grenades?.start;
      state.vodka = initialState.vodka;
      state.stew = initialState.stew;
    },

    defaultControl: (state: IStoreModule): void => {
      state.controlRun = initialState.controlRun;
      state.controlPause = initialState.controlPause;
      state.controlMap = initialState.controlMap;
      state.controlHelp = initialState.controlHelp;
      state.controlChat = initialState.controlChat;
      state.controlChatClose = initialState.controlChatClose;
      state.controlChatSend = initialState.controlChatSend;
      state.controlHide = initialState.controlHide;
      state.controlForward = initialState.controlForward;
      state.controlBackward = initialState.controlBackward;
      state.controlLeft = initialState.controlLeft;
      state.controlRight = initialState.controlRight;
      state.controlJump = initialState.controlJump;
      state.controlPick = initialState.controlPick;
    },
  },
};

export default persist;
