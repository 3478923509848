
import { defineComponent, ref, Ref, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store';
import { useI18n } from 'vue-i18n';

// Constants
import { Races, Things } from '@/utils/constants';

// Components
import GraphicsSwitch from '@/components/Layout/GraphicsSwitch.vue';
import AudioSwitch from '@/components/Layout/AudioSwitch.vue';
import UpgradeButton from '@/components/Layout/UpgradeButton.vue';

// Utils
import { beep, safeChar } from '@/utils/utils';

export default defineComponent({
  name: 'Help',

  components: {
    GraphicsSwitch,
    AudioSwitch,
    UpgradeButton,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore(key);

    const active: Ref<number> = ref(8);
    const config = computed(() => store.getters['persist/config']);
    const isEnter = computed(() => store.getters['persist/isEnter']);
    const locationData = computed(() => store.getters['api/locationData']);
    const sounds5 = computed(() => store.getters['persist/sounds5']);
    // const controlReserved = computed(() => store.getters['persist/controlReserved']);
    const controlRun = computed(() => store.getters['persist/controlRun']);
    const controlPause = computed(() => store.getters['persist/controlPause']);
    const controlMap = computed(() => store.getters['persist/controlMap']);
    const controlHelp = computed(() => store.getters['persist/controlHelp']);
    const controlChat = computed(() => store.getters['persist/controlChat']);
    const controlChatClose = computed(
      () => store.getters['persist/controlChatClose'],
    );
    const controlChatSend = computed(
      () => store.getters['persist/controlChatSend'],
    );
    const controlHide = computed(() => store.getters['persist/controlHide']);
    const controlForward = computed(
      () => store.getters['persist/controlForward'],
    );
    const controlBackward = computed(
      () => store.getters['persist/controlBackward'],
    );
    const controlLeft = computed(() => store.getters['persist/controlLeft']);
    const controlRight = computed(() => store.getters['persist/controlRight']);
    const controlJump = computed(() => store.getters['persist/controlJump']);
    const controlPick = computed(() => store.getters['persist/controlPick']);

    let close: () => void;
    let reenter: () => void;
    let defaultControl: () => void;

    defaultControl = () => {
      store.dispatch('persist/defaultControl');
      beep(false, sounds5.value);
    };

    reenter = () => {
      beep(false, sounds5.value);
      store
        .dispatch('persist/setPersistState', {
          field: 'last',
          value: locationData.value.id,
        })
        .then(() => {
          store
            .dispatch('not/setNotState', {
              field: 'isReload',
              value: true,
            })
            .then(() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.location.reload(true);
            });
        });
    };

    close = () => {
      store.dispatch('not/setNotState', {
        field: 'isHelp',
        value: false,
      });
    };

    return {
      t,
      active,
      Races,
      Things,
      config,
      close,
      reenter,
      isEnter,
      beep,
      sounds5,
      controlRun,
      controlPause,
      controlMap,
      controlHelp,
      controlChat,
      controlChatClose,
      controlChatSend,
      controlHide,
      controlForward,
      controlBackward,
      controlLeft,
      controlRight,
      controlJump,
      controlPick,
      safeChar,
      defaultControl,
    };
  },
});
