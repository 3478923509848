import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dc7f8a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat" }
const _hoisted_2 = { class: "chat__wrapper" }
const _hoisted_3 = { class: "chat__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: "input",
        class: "chat__input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
        maxlength: "200",
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
      }, null, 544), [
        [_vModelText, _ctx.text]
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.safeChar(_ctx.controlChatSend)) + _toDisplayString(_ctx.$t('chat1')) + _toDisplayString(_ctx.safeChar(_ctx.controlChatClose)) + _toDisplayString(_ctx.$t('chat2')), 1),
      _createElementVNode("button", {
        class: "chat__button chat__button--1",
        type: "button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent","stop"]))
      }, _toDisplayString(_ctx.$t('close')), 1),
      _createElementVNode("button", {
        class: _normalizeClass(["chat__button", { 'chat__button--disabled': !_ctx.text.length }]),
        type: "button",
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent","stop"]))
      }, _toDisplayString(_ctx.$t('send')), 3)
    ])
  ]))
}